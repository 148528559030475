.login-page {
  height: 100vh;
  width: 100vw;
  position: relative;

  .login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    font-family: sans-serif;

    input {
      all: unset;
      background-color: aliceblue;
      border-radius: 5px;
      padding: 5px;

    }

    button {
      all: unset;
      border: 1px solid grey;
      padding: 5px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background-color: lightblue;
        color: white;
        border-color: lightblue;
      }
    }
  }
}