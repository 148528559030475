.score-page {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  input {
    all: unset;
    border: 5px solid rgba(255, 0, 0, 0.6);
    border-radius: 10px;
    padding: 10px;
    font-size: 60px;
    width: 50vw;
    height: 100px;
  }

  input:focus {
    border: 5px solid rgb(0, 255, 0, 0.6);
  }

  .user {
    display: flex;
    column-gap: 2em;
    margin-top: 20px;

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 1em;
    }

    .user-id {
      font-size: 60px;
    }

    img {
      width: 300px;
      height: 300px;
      object-fit: cover;
      border-radius: 20px;
    }

    span, a {
      font-size: 30px;
    }

    div {
      font-size: 20px;
    }

    .red {
      color: red;
    }
  }
}